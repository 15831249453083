.topnav {
  background-color: var(--component-dark);
  display: flex; 
  align-items: center; 
  justify-content: space-between;
  position: fixed;
  top: 0; 
  left: 0;
  right: 0;
  height: 7vh; 
  z-index: 1000; 
  padding: 0 10px; 
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.2); 
}

.left-section, .right-section {
  display: flex;
  align-items: center;
}

.left-section {
  margin-left: 0;
}

.right-section {
  margin-right: 0;
}

.profile-link,
.navbar-link {
  display: flex;
  align-items: center;
  color: var(--text-color);
  text-decoration: none;
}

.profile-link {
  padding: 0; 
}

.profile-image {
  width: 40px;
  height: 40px;
  border-radius: 50%;
  object-fit: cover; 
}

.navbar-link {
  padding: 10px 12px; 
  border-radius: 5px; 
}

.navbar-link:hover {
  background-color: var(--secundary-color); 
  color: var(--text-color);
  transition: background-color 0.3s; 
}
