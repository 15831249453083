.item-container {
    position: relative;
    padding: 20px;
    border-radius: 8px;
    margin-bottom: 20px;
}

.form-actions {
    position: absolute;
    bottom: 10px;
    right: 10px;
    display: flex;
    gap: 10px;
}

.icon-wrapper {
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
}

.icon-wrapper:hover .form-action-icon {
    color: var(--secundary-color);
}

.form-action-icon {
    transition: color 0.3s ease;
}
