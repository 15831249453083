.platform-container {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    gap: 20px;
    padding: 20px;
}

.platform-item {
    background-color: var(--component-dark);
    box-shadow: 0 0 20px var(--background-shadow);
    border: none;
    border-radius: 8px;
    width: 200px;
    height: 150px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    text-align: center;
    font-size: 18px;
    font-weight: bold;
    cursor: pointer;
    transition: transform 0.3s ease;
    text-decoration: none;
    color: var(--text-color);
}

.platform-item:hover {
    transform: translateY(-5px);
    background-color: var(--secundary-color); 
    color: var(--text-color);
    transition: background-color 0.3s, transform 0.3s; 
}

.platform-icon {
    font-size: 2.5em;
    margin-bottom: 10px;
}
