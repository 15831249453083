.user-profile-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 20px;
    color: var(--text-color);
}

.user-profile-container form {
    display: flex;
    flex-direction: column;
    width: 100%;
    max-width: 400px;
}

.user-profile-container form div {
    margin-bottom: 10px;
}