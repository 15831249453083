/* DataModal.css */

.modal {
    display: flex;
    align-items: center;
    justify-content: center;
    position: fixed;
    z-index: 1000;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    background-color: var(--background-color-low-opacity);
}

.modal-content {
    background-color: var(--component-dark);
    padding: 30px;
    border-radius: 12px;
    max-width: 600px;
    width: 90%;
    max-height: 80%;
    overflow-y: auto;
    box-shadow: 0 4px 8px var(--background-shadow);
    position: relative;
}

.modal-content h2 {
    margin-top: 0;
    margin-bottom: 20px;
    font-size: 24px;
    color: var(--text-color);
}

.close {
    position: absolute;
    top: 15px;
    right: 15px;
    font-size: 30px;
    color: var(--text-color);
    cursor: pointer;
}

.close:hover {
    color: var(--secundary-color);
}

.form-group {
    margin-bottom: 20px;
}

.form-group label {
    display: block;
    margin-bottom: 10px;
    font-weight: 500;
}

.input-container {
    display: flex;
    align-items: center;
    gap: 10px;
}

input {
    flex: 1;
}

.whatsapp-button {
    font-size: 22px;
    color: var(--text-color);
    cursor: pointer;
}

.whatsapp-button:hover {
    color: var(--secundary-color);
}

.submit-button {
    margin-top: 20px;
}
