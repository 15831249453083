.login-container {
    display: flex;
    justify-content: center;
    align-items: start;
    padding-top: 10vh;
    height: 90vh;
    background-color: var(--background-color); 
}

.login-form {
    border-radius: 15px;
    padding: 20px; 
    width: 100%;
    max-width: 500px; 
    text-align: center;
    box-sizing: border-box;
}
