:root {
  --background-color: #070d18;
  --background-color-low-opacity: #070d189f;
  --background-shadow: #05080f;
  --component-dark: #0c182c;
  --text-color: white;
  --light-gray: #9b99aff7;
  --primary-color: #0077b6;
  --primary-color-darker: #024d76;
  --secundary-color: #e7a500;
  --secundary-color-darker: #e7a500;
  --red-color: #d32f2f;
  --red-color-darker: #741c1c;
}

body, html {
  margin: 0;
  padding: 0;
  overflow-x: hidden;
  font-family: Arial, sans-serif;
  background-color: var(--background-color);
  color: var(--text-color);
}

label {
  display: block;
  width: 100%;
  margin-top: 10px;
  margin-bottom: 5px;
  text-align: left;
  color: var(--text-color);
  font-weight: bold;
}

h1 {
  color: var(--primary-color);
}

input, select {
  background-color: var(--primary-color-darker);
  color: var(--text-color);
  border: none;
  border-radius: 5px;
  padding: 10px;
  box-sizing: border-box;
  width: 100%;
  margin-bottom: 15px;
  font-size: 16px;
  outline: none;
  appearance: none;
}

input::placeholder {
  color: var(--light-gray);
}

input:focus, select:focus {
  outline: none;
  box-shadow: 0 0 0 2px var(--primary-color-darker);
  border: solid 1px var(--secundary-color);
}

select {
  cursor: pointer;
}

select option {
  background-color: var(--component-dark);
  color: var(--text-color);
}

select option:checked {
  background-color: var(--primary-color);
  color: var(--text-color);
}

button {
  padding: 10px;
  border-radius: 7px;
  margin-top: 10px;
  margin-bottom: 10px;
  border: none;
  color: var(--text-color);
  cursor: pointer;
  background-color: var(--primary-color);
  width: 100%;
  font-size: 16px;
}

button:hover {
  background-color: var(--secundary-color); 
  color: var(--text-color); 
  transition: background-color 0.3s;
}

img {
  max-width: 100%;
  height: auto;
}

table {
  width: 100%;
  border-collapse: collapse;
  margin-top: 20px;
}

th, td {
  border: 1px solid var(--primary-color-darker);
  padding: 8px;
  text-align: left;
}

th {
  background-color: var(--primary-color-darker);
}

.app {
  padding-top: 7vh;
  background-color: var(--background-color);
  color: var(--text-color);
  width: 100vw;
  height: 100vh;
  padding: 7vh 5vw;
  box-sizing: border-box;
}

.item-actions {
  display: flex;
  justify-content: space-between;
  align-items: end;
  min-height: 40px; 
}

.item-action-button {
  background-color: var(--primary-color-darker);
  width: auto; 
  padding: 10px 20px; 
}
