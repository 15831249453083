.students-modal-overlay {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: var(--background-color-low-opacity);
    display: flex;
    justify-content: center;
    align-items: center;
    overflow-y: auto;
}

.students-modal-content {
    background: var(--component-dark);
    padding: 20px;
    border-radius: 5px;
    width: 500px;
    max-width: 100%;
    max-height: 80vh; /* Ajusta la altura máxima al 80% del viewport */
    overflow-y: auto; /* Habilita el scroll vertical si el contenido excede la altura máxima */
    position: relative;
}

/* StudentsListModal.css */
.submited-select, .present-select {
    background-color: #4f772d;
    color: white;
}

.excused-select {
    background-color: #fb8b24;
    color: white;
}

.not-submited-select, .absent-select{
    background-color: #c1121f;
    color: white;
}


.students-item {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 10px;
}

.students-item p {
    flex: 1;
}

.students-item select {
    flex: 1;
    padding: 5px;
}
