.question-editor {
    margin: 20px 0;
    max-width: 800px;
    margin-left: auto;
    margin-right: auto;
    color: var(--text-color);
}

.question-item {
    background-color: var(--component-dark);
    padding: 20px;
    margin-bottom: 20px;
    border-radius: 8px;
    box-shadow: 0 1px 3px var(--background-shadow);
    transition: box-shadow 0.2s ease, transform 0.2s ease;
}

.question-item:hover {
    box-shadow: 0 1px 5px var(--background-shadow);
    transform: scale(1.02);
}

.question-header {
    display: flex;
    flex-direction: column;
    gap: 15px;
    margin-bottom: 15px;
}

.question-input {
    width: 100%;  
    padding: 10px;
    font-size: 16px;
    border-radius: 4px;
    background-color: var(--background-color);
    color: var(--text-color);
}

.question-type-select {
    width: 100%;  
    padding: 10px;
    font-size: 16px;
    border-radius: 4px;
    background-color: var(--background-color);
    color: var(--text-color);
}

.options-container {
    margin-top: 15px;
    display: flex;
    flex-direction: column;
    gap: 10px; 
}

.option-item {
    display: flex;
    align-items: center;
    gap: 10px; 
}

.option-input {
    flex:auto;
    padding: 8px;
    font-size: 14px;
    border-radius: 4px;
    background-color: var(--background-color);
    color: var(--text-color);
}

.question-actions {
    display: flex;
    gap: 10px;
    justify-content: flex-end;
    align-items: center;
    margin-top: 10px;
}

.question-actions button,
.option-item button {
    padding: 0;  
    margin: 0;
    border: none;  
    background: none; 
    cursor: pointer; 
    color: var(--light-gray);  
    font-size: 18px;  
    transition: color 0.2s ease;
    width: auto; 
    height: auto; 
    line-height: 1; 
    display: inline-flex; 
    align-items: center;
    justify-content: center;
    flex-shrink: 0; 
}

.question-actions button:hover,
.option-item button:hover {
    color: var(--secundary-color); 
}

.add-option-btn,
.add-question-btn {
    padding: 8px 12px; 
    cursor: pointer;
    background-color: var(--primary-color);
    color: var(--text-color);
    border: none;
    border-radius: 4px;
    font-size: 14px;
    transition: background-color 0.2s ease;
}

.add-option-btn:hover,
.add-question-btn:hover {
    background-color: var(--primary-color-darker);
}

.add-question-container {
    margin-top: 20px;
    display: flex;
    justify-content: center;
}

.add-question-btn {
    padding: 10px 20px;
    font-size: 16px;
    border-radius: 8px;
    background-color: var(--primary-color);
    color: var(--text-color);
    transition: background-color 0.2s ease;
}

.add-question-btn:hover {
    background-color: var(--primary-color-darker);
}

.question-image-preview {
    width: 100%; 
    max-width: 400px;
    height: auto;
    display: block; 
    margin: 0 auto 20px auto; 
    border-radius: 3%;
}

@media (max-width: 768px) {
    .question-image-preview {
        max-width: 100%; 
    }
}

@media (max-width: 480px) {
    .question-image-preview {
        max-width: 80%; 
        margin: 10px auto;
    }
}

.correct-answer-checkbox {
    width: 16px;           /* Tamaño del checkbox */
    height: 16px;          /* Tamaño del checkbox */
    cursor: pointer;       /* Cambia el cursor al pasar */
    accent-color: #007BFF; /* Color del tema, ajusta a tu esquema de colores */
    border: 1px solid #ccc; /* Bordes claros para cuando no está marcado */
    background-color: white; /* Fondo blanco para cuando no está marcado */
    appearance: none;      /* Remueve el estilo del navegador para personalizar */
    display: inline-block; /* Asegura el tamaño del checkbox */
    position: relative;    /* Para los estilos de marcado */
}

.correct-answer-checkbox:checked {
    background-color: #007BFF; /* Fondo azul cuando está marcado */
    border: 1px solid #007BFF; /* Bordes del mismo color del tema */
    box-shadow: 0 0 0 2px rgba(0, 123, 255, 0.5); /* Sombra clara para marcar el estado */
}

.correct-answer-checkbox::before {
    content: "";
    display: block;
    width: 6px;
    height: 10px;
    border: solid white;
    border-width: 0 2px 2px 0;
    transform: rotate(45deg);
    position: absolute;
    top: 2px;
    left: 5px;
    opacity: 0; /* Invisible si no está marcado */
}

.correct-answer-checkbox:checked::before {
    opacity: 1; /* Visible si está marcado */
}

